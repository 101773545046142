import {
  BrandingWatermark,
  Gavel,
  Room,
  Description,
  AccountBalance,
  Receipt,
  Apartment,
  InsertDriveFile,
  Phone,
  House,
  Store,
  Check,
  CheckCircle,
  CloudUpload,
  PermContactCalendar,
  LocationOn,
  CameraAlt,
  Create,
  Clear,
  Add,
  Person,
  StayCurrentPortrait,
  HourglassEmpty,
  AddShoppingCart,
  LocalAtm,
  LocalOffer,
  LocalMall,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  AccountCircle,
  Face,
  AssignmentInd,
  AddLocation,
  PieChart,
  MoreVert,
  Remove,
  Email,
  Error,
  ErrorOutline,
  Warning,
  FileCopyOutlined,
  AddOutlined,
  Delete,
  Image,
  NoteAdd,
  Link,
  EmojiEvents,
  HelpOutline,
  Accessibility,
  Info,
  AttachMoney,
  Lock,
  PhoneIphone,
  VpnKey,
  Apple,
  Android,
  ArrowDropDown,
  ArrowDropUp,
  MonetizationOnOutlined,
  DescriptionOutlined,
  StorefrontOutlined,
  People,
  Report,
} from '@material-ui/icons';

const MaterialIcons = [
  { id: 'BrandingWatermark', icon: BrandingWatermark },
  { id: 'cash', icon: LocalAtm },
  { id: 'tag', icon: LocalOffer },
  { id: 'bag', icon: LocalMall },
  { id: 'AddShoppingCart', icon: AddShoppingCart },
  { id: 'Gavel', icon: Gavel },
  { id: 'Room', icon: Room },
  { id: 'Description', icon: Description },
  { id: 'AccountBalance', icon: AccountBalance },
  { id: 'StoreFront', icon: StorefrontOutlined },
  { id: 'Store', icon: Store },
  { id: 'Check', icon: Check },
  { id: 'check-circle', icon: CheckCircle },
  { id: 'CloudUpload', icon: CloudUpload },
  { id: 'Create', icon: Create },
  { id: 'Clear', icon: Clear },
  { id: 'Add', icon: Add },
  { id: 'Plus', icon: Add },
  { id: 'Person', icon: Person },
  { id: 'Phone', icon: StayCurrentPortrait },
  { id: 'Hourglass', icon: HourglassEmpty },
  { id: 'ArrowLeft', icon: KeyboardArrowLeft },
  { id: 'ArrowRight', icon: KeyboardArrowRight },
  { id: 'Account', icon: AccountCircle },
  { id: 'Face', icon: Face },
  { id: 'UserBadge', icon: AssignmentInd },
  { id: 'AddLocation', icon: AddLocation },
  { id: 'PieChart', icon: PieChart },
  { id: 'merchant-store', icon: Store },
  { id: 'user', icon: Person },
  { id: 'customer', icon: Person },
  { id: 'more_vert', icon: MoreVert },
  { id: 'remove', icon: Remove },
  { id: 'email', icon: Email },
  { id: 'emails', icon: Email },
  { id: 'error', icon: Error },
  { id: 'errorOutline', icon: ErrorOutline },
  { id: 'warning', icon: Warning },
  { id: 'copy', icon: FileCopyOutlined },
  { id: 'add', icon: AddOutlined },
  { id: 'delete', icon: Delete },
  { id: 'image', icon: Image },
  { id: 'note_add', icon: NoteAdd },
  { id: 'link', icon: Link },
  { id: 'link', icon: Link },
  { id: 'trophy', icon: EmojiEvents },
  { id: 'perm-contact-calendar', icon: PermContactCalendar },
  { id: 'location-on', icon: LocationOn },
  { id: 'camera-alt', icon: CameraAlt },
  { id: 'receipt-long', icon: Receipt },
  { id: 'apartment', icon: Apartment },
  { id: 'house', icon: House },
  { id: 'call', icon: Phone },
  { id: 'mobiles', icon: Phone },
  { id: 'insert-drive-file', icon: InsertDriveFile },
  { id: 'help-outline', icon: HelpOutline },
  { id: 'accessibility', icon: Accessibility },
  { id: 'info', icon: Info },
  { id: 'dollar', icon: AttachMoney },
  { id: 'lock', icon: Lock },
  { id: 'phone', icon: PhoneIphone },
  { id: 'password', icon: VpnKey },
  { id: 'android', icon: Android },
  { id: 'ios', icon: Apple },
  { id: 'arrowDropDown', icon: ArrowDropDown },
  { id: 'arrowDropUp', icon: ArrowDropUp },
  { id: 'monetization', icon: MonetizationOnOutlined },
  { id: 'description', icon: DescriptionOutlined },
  { id: 'people', icon: People },
  { id: 'report', icon: Report },
];

export default MaterialIcons;
