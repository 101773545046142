import * as React from 'react';

export default function FAQ(props) {
  const { width = 150, height = 150, primary } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200">
      <defs>
        <clipPath id="clip-FAQ">
          <rect width="200" height="200" />
        </clipPath>
      </defs>
      <g id="FAQ" clip-path="url(#clip-FAQ)">
        <g
          id="Path_62326"
          data-name="Path 62326"
          transform="matrix(0.875, 0.485, -0.485, 0.875, 78.053, -5.95)"
          fill="none">
          <path
            d="M72.5,0A72.5,72.5,0,0,1,145,72.5c0,33.874-18.413,61.186-50.07,69.21-5.459,1.384-16.264,29.338-22.43,29.338-7.448,0-16.338-27.171-23.363-29.338C19.8,132.663,0,105.093,0,72.5A72.5,72.5,0,0,1,72.5,0Z"
            stroke="none"
          />
          <path
            d="M 72.5 7.999984741210938 C 36.93458557128906 7.999984741210938 8 36.9345703125 8 72.50001525878906 C 8 101.3006591796875 25.47899627685547 126.0416870117188 51.49491119384766 134.0651245117188 C 57.81535339355469 136.0149841308594 61.80461883544922 143.0268707275391 66.85523223876953 151.9041900634766 C 68.31887054443359 154.4768218994141 70.43495941162109 158.1962432861328 72.21473693847656 160.7819061279297 C 74.08681488037109 158.0273742675781 76.35471343994141 154.1053619384766 77.92514801025391 151.3895416259766 C 83.99014282226562 140.9010772705078 87.41352844238281 135.3623352050781 92.96473693847656 133.954833984375 C 120.1266784667969 127.0706634521484 137 103.522575378418 137 72.50001525878906 C 137 36.9345703125 108.0654144287109 7.999984741210938 72.5 7.999984741210938 M 72.5 -1.52587890625e-05 C 112.5406494140625 -1.52587890625e-05 145 32.45933532714844 145 72.50001525878906 C 145 106.3743667602539 126.5866470336914 133.6863403320312 94.93017578125 141.7096252441406 C 89.47105407714844 143.0938110351562 78.66626739501953 171.0478363037109 72.5 171.0478363037109 C 65.05244445800781 171.0478363037109 56.16217803955078 143.8770141601562 49.13658905029297 141.7096252441406 C 19.80244445800781 132.662841796875 0 105.0930709838867 0 72.50001525878906 C 0 32.45933532714844 32.4593505859375 -1.52587890625e-05 72.5 -1.52587890625e-05 Z"
            stroke="none"
            fill="#020d88"
          />
        </g>
        <path
          id="Path_62586"
          data-name="Path 62586"
          d="M4.151-55.245H18.575a10.385,10.385,0,0,1,3.087-7.212,10.281,10.281,0,0,1,7.4-2.688A11.756,11.756,0,0,1,34.382-64a8.744,8.744,0,0,1,3.539,3.087,8.124,8.124,0,0,1,1.251,4.5,11.21,11.21,0,0,1-.8,4.391,10.075,10.075,0,0,1-2.741,3.593,38.121,38.121,0,0,1-5.4,3.779,25.628,25.628,0,0,0-5.881,4.577,13.5,13.5,0,0,0-3.14,5.562A21.153,21.153,0,0,0,20.65-27.2l.106,3.087h14.1v-2.821a11.714,11.714,0,0,1,.8-4.524A10.116,10.116,0,0,1,38.427-35.1a34.682,34.682,0,0,1,5.482-3.752,25.207,25.207,0,0,0,8.516-7.637A18.407,18.407,0,0,0,55.3-56.9a18.615,18.615,0,0,0-3.167-10.724A21.008,21.008,0,0,0,43.3-74.884a31.5,31.5,0,0,0-13.279-2.608,31.033,31.033,0,0,0-13.891,2.847,20.723,20.723,0,0,0-8.782,7.85A22.944,22.944,0,0,0,4.151-55.245ZM28.368,1.543a9.963,9.963,0,0,0,7.052-2.4A8.426,8.426,0,0,0,38-7.345a8.474,8.474,0,0,0-2.581-6.573,10.032,10.032,0,0,0-7.052-2.368,10.132,10.132,0,0,0-7.079,2.368,8.429,8.429,0,0,0-2.608,6.573A8.382,8.382,0,0,0,21.289-.852,10.061,10.061,0,0,0,28.368,1.543Z"
          transform="translate(76 133)"
          fill="#fff"
        />
        <path
          id="primary"
          d="M4.151-55.245H18.575a10.385,10.385,0,0,1,3.087-7.212,10.281,10.281,0,0,1,7.4-2.688A11.756,11.756,0,0,1,34.382-64a8.744,8.744,0,0,1,3.539,3.087,8.124,8.124,0,0,1,1.251,4.5,11.21,11.21,0,0,1-.8,4.391,10.075,10.075,0,0,1-2.741,3.593,38.121,38.121,0,0,1-5.4,3.779,25.628,25.628,0,0,0-5.881,4.577,13.5,13.5,0,0,0-3.14,5.562A21.153,21.153,0,0,0,20.65-27.2l.106,3.087h14.1v-2.821a11.714,11.714,0,0,1,.8-4.524A10.116,10.116,0,0,1,38.427-35.1a34.682,34.682,0,0,1,5.482-3.752,25.207,25.207,0,0,0,8.516-7.637A18.407,18.407,0,0,0,55.3-56.9a18.615,18.615,0,0,0-3.167-10.724A21.008,21.008,0,0,0,43.3-74.884a31.5,31.5,0,0,0-13.279-2.608,31.033,31.033,0,0,0-13.891,2.847,20.723,20.723,0,0,0-8.782,7.85A22.944,22.944,0,0,0,4.151-55.245ZM28.368,1.543a9.963,9.963,0,0,0,7.052-2.4A8.426,8.426,0,0,0,38-7.345a8.474,8.474,0,0,0-2.581-6.573,10.032,10.032,0,0,0-7.052-2.368,10.132,10.132,0,0,0-7.079,2.368,8.429,8.429,0,0,0-2.608,6.573A8.382,8.382,0,0,0,21.289-.852,10.061,10.061,0,0,0,28.368,1.543Z"
          transform="translate(76 133)"
          fill={primary}
          opacity="0.35"
        />
      </g>
    </svg>
  );
}
