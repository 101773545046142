import error from './error';
import loading from './loading';
import success from './success';
import splashLoading from './splash-loading';
import payment from './payment-pending';
import loadingQr from './loading-qrcode';
import bankLoading from './bank-loading';

export default {
  error,
  loading,
  success,
  splashLoading,
  payment,
  'loading-qr': loadingQr,
  bankLoading,
};
