import { get } from 'lodash';

export const cryptoTypes = [
  { index: 0, id: 'all', name: 'All' },
  { index: 1, id: 'bitcoin', name: 'Bitcoin' },
  { index: 2, id: 'ethereum', name: 'Ethereum' },
  { index: 3, id: 'stellar', name: 'Stellar' },
];

export const addCryptoToAccount = ({ currency, crypto, account }) => {
  currency.account = account.reference;
  currency.account_name = account.name;
  currency.account_label = account.label;
  let currencyCode = currency?.currency?.code;
  if (
    (currencyCode === 'XLM' && crypto.XLM) ||
    (crypto.XLM &&
      crypto.XLM.assets &&
      crypto.XLM.assets.indexOf(currencyCode) !== -1)
  ) {
    currency.crypto = 'XLM';
  } else if (
    (currencyCode === 'TXLM' && crypto.TXLM) ||
    (crypto.TXLM &&
      crypto.TXLM.assets &&
      crypto.TXLM.assets.indexOf(currencyCode) !== -1)
  ) {
    currency.crypto = 'TXLM';
  } else if (
    (currencyCode === 'ETH' && crypto.ETH) ||
    (currencyCode === 'TETH' && crypto.TETH) ||
    (currencyCode === 'XBT' && crypto.XBT) ||
    (currencyCode === 'TXBT' && crypto.TXBT)
  ) {
    currency.crypto = currencyCode;
  } else {
    currency.crypto = '';
  }

  return currency;
};

export const checkIfCrypto = ({ currency, crypto }) => {
  let currencyCode = currency.code;
  if (
    (currencyCode === 'XLM' && crypto.XLM) ||
    crypto.XLM?.assets?.includes(currencyCode)
  ) {
    return 'XLM';
  } else if (
    (currencyCode === 'TXLM' && crypto.TXLM) ||
    crypto.TXLM?.assets?.includes(currencyCode)
  ) {
    return 'TXLM';
  } else if (
    (currencyCode === 'ETH' && crypto.ETH) ||
    (currencyCode === 'TETH' && crypto.TETH) ||
    (currencyCode === 'XBT' && crypto.XBT) ||
    (currencyCode === 'TXBT' && crypto.TXBT)
  ) {
    return currencyCode;
  } else {
    return '';
  }
};

export function checkIfStellar({ wallet, wyreCurrency }) {
  const cryptoCode =
    (wyreCurrency?.data ?? wyreCurrency)?.wyre_currency_code_for_deposit ??
    wallet?.crypto;
  return cryptoCode.includes('XLM');
}

export function stellarFederation(currency, crypto, isCrypto) {
  let isFederated = false;
  let setUsername = false;

  let federatedAddress = '';
  let address = '';
  let memo = '';
  let federatedAddressLabel = '';

  let isStellar = isCrypto && currency.crypto.match(/TXLM|XLM/);

  if (isCrypto && isStellar) {
    const currentCrypto = crypto?.[currency?.crypto] ?? null;
    if (currentCrypto) {
      const { company, user } = currentCrypto;
      if (company && user) {
        setUsername = user.username ? false : true;
        address = user?.crypto?.public_address;
        memo = user?.crypto?.memo;
        if (company.is_federated) {
          isFederated = true;
          federatedAddress =
            (!setUsername ? user.username : user.memo) +
            '*' +
            company.federation_domain;
          federatedAddressLabel =
            'Stellar' +
            (currency.crypto === 'TXLM' ? ' testnet' : '') +
            ' federated address';
        }
      }
    } else {
      isStellar = false;
    }
  }

  return {
    isFederated,
    isStellar,
    setUsername,
    federatedAddress,
    address,
    federatedAddressLabel,
    memo,
  };
}

export const getCryptoAddress = (currency, crypto, stellarTransactionType) => {
  switch (currency.crypto) {
    case 'XBT':
    case 'TXBT':
      return getBitcoinAddress(currency, crypto);
    case 'XLM':
    case 'TXLM':
      return getStellarAddress(currency, crypto, stellarTransactionType);
    case 'ETH':
    case 'TETH':
      return getEthereumAddress(currency, crypto);
    default:
      return '';
  }
};

export const getStellarAddress = (currency, crypto, stellarTransactionType) => {
  let address = '';
  if (stellarTransactionType === 'public') {
    address = get(
      crypto,
      [currency.crypto, 'user', 'crypto', 'public_address'],
      '',
    );
  } else {
    const currentCrypto = get(crypto, [currency.crypto], null);
    if (currentCrypto) {
      const { company, user } = currentCrypto;
      address =
        (user.username ? user.username : user.memo) +
        '*' +
        get(company, 'federation_domain', '');
    }
  }

  return address;
};

export const getBitcoinAddress = (currency, crypto) =>
  get(crypto, [currency.crypto, 'user', 'account_id'], '');

export const getEthereumAddress = (currency, crypto) =>
  get(crypto, [currency.crypto, 'user', 'crypto', 'address'], '');

export const compareCryptoType = (acc, currency) => {
  if (
    (acc.crypto_type === 'stellar' &&
      acc.network.match(/livenet|mainnet/) &&
      currency.crypto === 'XLM') ||
    (acc.crypto_type === 'stellar' &&
      acc.network === 'testnet' &&
      (currency.crypto === 'TXLM' || currency.crypto === 'XLM')) ||
    (acc.crypto_type === 'bitcoin' &&
      acc.network.match(/livenet|mainnet/) &&
      currency.crypto.match(/XBT|BTC/)) || // wyre_code is BTC
    (acc.crypto_type === 'bitcoin' &&
      acc.network === 'testnet' &&
      currency.crypto.match(/TXBT|XBT|BTC/)) ||
    (acc.crypto_type === 'ethereum' &&
      acc.network.match(/livenet|mainnet/) &&
      currency.crypto === 'ETH') ||
    (acc.crypto_type === 'ethereum' &&
      acc.network === 'testnet' &&
      currency.crypto.match(/TETH|ETH/))
  ) {
    return true;
  }
  return false;
};

export function generateTxHashLink(tx_hash, type, testnet) {
  let link = 'https://';

  switch (type) {
    case 'bitcoin':
      link = link + 'live.blockcypher.com/btc' + (testnet ? '-testnet' : '');
      break;
    case 'stellar':
      link =
        link + 'stellar.expert/explorer/' + (testnet ? 'testnet' : 'public');
      break;
    default:
  }
  return link + '/tx/' + tx_hash + '/';
}
export function generateRecipientLink(address, type, testnet) {
  let link = 'https://';

  switch (type) {
    case 'bitcoin':
      link =
        link +
        'live.blockcypher.com/btc' +
        (testnet ? '-testnet' : '') +
        '/address';
      break;
    case 'stellar':
      link =
        link +
        'stellar.expert/explorer/' +
        (testnet ? 'testnet' : 'public') +
        '/account';
      break;
    default:
  }
  return link + '/' + address + '/';
}
