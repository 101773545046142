const en = {
  product_id: 'Product ID',
  product_name: 'Product name',
  product_description: 'Product description',
  short_description: 'Short description',
  countries: 'Countries',
  product_type: 'Product type',
  account: 'Account',
  bar_code: 'Code (barcode)',
  quantity: 'Quantity',
  enabled: 'Enabled',
  instant_buy: 'Instant buy',
  requires_billing_address: 'Requires billing address',
  requires_shipping_address: 'Requires shipping address',
  requires_contact_mobile: 'Requires contact mobile',
  requires_contact_email: 'Requires contact email',
  metadata: 'Metadata',
  created: 'Date created',
  updated: 'Date updated',
  images: 'Images',
  prices: 'Prices',
  categories: 'Categories',
  order_id: 'Order ID',
  user_id: 'User ID',
  status: 'Status',
  currency: 'Currency',
  placed: 'Date placed',
  code_id: 'Code ID',
  parent_category: 'Parent category',
  category_name: 'Category name',
  pricing_quantity_and_variants: 'Pricing, quantity and variants',
  voucher_code: 'Voucher code',
  format: 'Format',
  date_created: 'Date created',
  date_updated: 'Date updated',
  // product_image_cover_help:
  //   'We recommend using 2048 x 2048 pixels for square product photos. Ensure images are less than 3MB',
  product_image_crop_help:
    'We recommend using 2048 x 2048 pixels for square product photos. Ensure images are less than 3MB',
  options_helper_text:
    'Options are the different classifications given to products, such as size and color, and have multiple values',
  variants_helper_text:
    'Variants are the actual products consisting of a combination of options, a unique code, can be tracked or untracked (having a quantity), and a price in each available currency',
};

export default en;
